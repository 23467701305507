<template>
  <div class="hello">
    <p style="margin-top: 20vh">
      密码服务管理平台密码模块
    </p>
    <p>
      CCSP
    </p>
    <el-button v-if="type == 1"  style="margin-top: 20px" type="primary" @click="bind">点击绑定</el-button>
    <el-button v-if="type == 3" :loading="true" style="margin-top: 20px" type="primary">绑定中</el-button>
    <el-button v-if="type == 2" style="margin-top: 20px" type="primary" @click="bind">点击登录</el-button>
    <el-button v-if="type == 4" :loading="true" style="margin-top: 20px" type="primary">登录中</el-button>

    <el-table v-if="bindList.length>0" :data="bindList" border fit highlight-current-row style="width: 100%">
      <el-table-column align="center" label="账号">
        <template slot-scope="scope">
          <span>{{ scope.row.adminId }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账号类型">
        <template slot-scope="scope">
          <span>{{ scope.row.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="dispatchScene(scope.row.scene, scope.row.code, scope.row.adminId)">
            登录
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-result icon="success" v-if="type == 5" title="登录成功" subTitle="请继续在网页操作">

    </el-result>
    <el-result icon="error" v-if="type == 6" title="错误" subTitle="请退出重新扫码登录">
      <template slot="extra">
        <label>{{msg}}</label>
      </template>
    </el-result>
    <el-result icon="success" v-if="type == 7" title="绑定成功" >

    </el-result>
    <el-result icon="error"  v-if="type == 8" title="错误" subTitle="请退出重新扫码绑定">
      <template slot="extra">
        <label>{{msg}}</label>
      </template>
    </el-result>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      isWXBrowser: false,
      type: 0, //1 带绑定 2 登录 3 绑定中 4 登录中 5扫码登录成功 6绑定成功
      msg: null,
      bindList: []
    };
  },
  created() {
    this.isWXBrowser = this.checkWXBrowser();
    if (this.isWXBrowser) {
      let scene = this.getUrlCode("scene");
      if (scene) {
        let code = this.getUrlCode("code");
        if (code) {
          // this.wxLogin(code); //后台登录
          console.log(code)
          console.log(scene)
          this.dispatchScene(scene, code)
        } else {
          this.dispatchScene(scene)
        }
      } else {
        // 扫码有问题
      }

    }
  },
  methods: {
    //判断是否是微信内置的浏览器
    checkWXBrowser() {
      return (
          String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
          "micromessenger"
      );
    },
    //截取地址栏code
    getUrlCode(name) {
      return (
          decodeURIComponent(
              (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                  location.href
                  // eslint-disable-next-line no-sparse-arrays
              ) || [, ""])[1].replace(/\+/g, "%20")
          ) || null
      );
    },
    //访问微信地址，用来获取code
    getWeChatCode() {
      // let local = encodeURIComponent('http://ccsp-wx.cinfsec.cn'); //获取当前页面地址作为回调地址
      let local = encodeURIComponent(window.location.href); //获取当前页面地址作为回调地址
      let appid =  process.env.VUE_APP_WX_APPID;//测试
      // let appid = 'wx90fa9bbf0a55c5ca';//生产
      //通过微信官方接口获取code之后，会重新刷新设置的回调地址【redirect_uri】
      window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          "&redirect_uri=" +
          local +
          "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
    },

    bind() {
      this.getWeChatCode(); //微信授权
    },

    doBind(scene, code) {
      this.$axios({
        method: "post",
        url: `${process.env.VUE_APP_BASE_API}/api/v1/mgt/preview/binding/qr/bind`,
        data: {
          code,
          scene,
        }
      }).then(response => {
        console.log(response)
        if (response) {
          if (response.data.code == 0) {
            this.type = 7
          } else {
            this.type = 8
            this.msg = response.data.message
          }
        }
      })
    },

    doLogin(scene, code, adminId) {
      this.$axios({
        method: "post",
        url: `${process.env.VUE_APP_BASE_API}/api/v1/mgt/preview/entry/qr/login`,
        data: {
          ticket: code,
          scene,
          adminId
        }
      }).then(response => {
        console.log(response)
        if (response) {
          if (response.data.code == 0) {
            this.type = 5
          } else {
            this.type = 6
            this.msg = response.data.message
          }
        }
      })
    },

    getBindAdmin(code, scene) {
      this.bindList = []
      this.$axios({
        method: "post",
        url: `${process.env.VUE_APP_BASE_API}/api/v1/mgt/preview/entry/login/boundAdminInfo/wxQuery`,
        data: {
          code,
        }
      }).then(response => {
        console.log(response)
        if (response) {
          if (response.data.code == 0) {
            this.type = -1
            let ticket = response.data.data.ticket
            response.data.data.boundAdminInfoList.forEach(item =>{
              item.code = ticket;
              item.scene = scene
            })
            this.bindList = response.data.data.boundAdminInfoList
          } else {
            this.type = 6
            this.msg = response.data.message
          }
        }
      })
    },

    dispatchScene(scene, code, adminId) {
      let sceneType = scene.split(':')[0] - 0
      switch (sceneType) {
        case 1:
          if (code) {
            this.type = 3
            //调用绑定接口
            this.doBind(scene, code)
          } else {
            this.type = 1
          }
          break
        case 2:
          if (code) {
            if (adminId) {
              this.type = 4
              this.bindList = []
              this.doLogin(scene, code, adminId)
            } else{
              this.getBindAdmin(code, scene)
              this.type = 4
            }
          } else {
            this.type = 2
          }
          break
      }
    },

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
